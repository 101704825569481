<template>
  <TransitionRoot as="template" :show="props.isPreview">
    <Dialog as="div" class="relative z-10" @close="props.isPreview = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            >
              <img :src="props.imgUrl" class="mb-5"/>
              <hr/>

              <button v-if="eventStore.isOwner" type="button" @click="onSwitchStatus" 
              :class="{'bg-red-700': isActive,'bg-green-600 ': !isActive, }"
              class="block w-full mt-2 py-2.5 px-5 mr-2 text-white text-sm font-medium text-gray-900  rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ">
                {{isActive ? "Disable": "Active"}}
              </button>

              <button type="button" @click="close" class="block w-full mt-2 py-2.5 px-5 mr-2  text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 ">Close</button>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/outline";

const eventStore = useEventStore();
const emit = defineEmits(['closed', 'changeStatus'])
const props = defineProps({
  imgUrl: {
    type: String,
    default: "",
  },
  isPreview: {
    type: Boolean,
    default: false,
  },
  isActive:{
    type:Boolean,
    default:false
  },
  saveTemplateSelected:{
    type:Function
  },
  templateId:{
    type:String
  }
});

const onSwitchStatus = ()=>{
  emit("changeStatus")
  close()
}

const close = ()=>{
  emit("closed")
}

</script>
